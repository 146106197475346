import React, { useEffect } from 'react';

import { useRouter } from 'next/router';

import { routes } from '@/helpers/routes';

import { StyledLoader } from '../lib';

export const NotFound = () => {
  const { push } = useRouter();

  const pathname = window?.location?.pathname;
  const queryParams = window?.location?.search;

  const route = () => {
    switch (pathname) {
      case routes.samples:
        return `${routes.samples}${queryParams}`;
      case routes.workspaceSettings:
        return `${routes.workspaceSettings}${queryParams}`;
      case routes.onboarding:
        return `${routes.onboarding}${queryParams}`;
      case routes.config.apiKey:
        return `${routes.config.apiKey}${queryParams}`;
      case routes.config.versions:
        return `${routes.config.versions}${queryParams}`;
      case routes.config.applications:
        return `${routes.config.applications}${queryParams}`;
      case routes.config.properties:
        return `${routes.config.properties}${queryParams}`;
      case routes.config.interactionTypes:
        return `${routes.config.interactionTypes}${queryParams}`;
      case routes.licenseAgreement:
        return `${routes.licenseAgreement}${queryParams}`;
      default:
        return `${routes.overview}${queryParams}`;
    }
  };

  useEffect(() => void push(route(), undefined, { shallow: true }), []);

  return <StyledLoader />;
};
