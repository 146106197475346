import React from 'react';

import Head from 'next/head';

import { routesMetaData } from '@/helpers/routes';

import { NotFound } from '@/components/NotFound/NotFound';

const NotFoundPage = () => (
  <>
    <Head>
      <title>{`${routesMetaData.title}${routesMetaData.notFound}`}</title>
    </Head>
    <NotFound />
  </>
);

export default NotFoundPage;
